/* src/styles/NotFoundPage.module.css */
.notFoundBody {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.7s ease-in-out;
  }
  
  .notFoundContainer {
    text-align: center;
    padding: 40px;
    max-width: 600px;
  }
  
  .notFoundTitle {
    font-size: 6rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .notFoundMessage {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .homeLink {
    font-size: 1.2rem;
    color: #667eea;
    text-decoration: none;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .homeLink:hover {
    background-color: #667eea;
    color: #fff;
  }
  
  /* Animation for the entire page */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  