/* Centering the loading animation */
.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  width: calc(100% - var(--navbar-width)); /* Adjust for the navbar width */
  margin-left: var(--navbar-width); /* Offset to account for the navbar */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; /* Ensure it stays on top of the content */
  background-color: transparent; /* No background color to keep the navbar visible */
}

/* Spinner styling */
.spinner {
  width: 5rem;
  height: 5rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-top: 0.5rem solid black; /* Black border for the top to create the spinning effect */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Loading text styling */
.loading-text {
  color: black; /* Black color for the text */
  font-size: 4rem;
  margin-top: 0.5em; /* Space between spinner and text */
  animation: pulse 1.5s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
