.excel-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.negative-value {
  color: red;
}

.yearly-values-table {
  position: absolute;
  top: -0.30rem;
  left: 6.65rem;
  z-index: 6;
  border-collapse: collapse;
  background-color: white;
}

.yearly-values-table th,
.yearly-values-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.yearly-values-table th {
  background-color: #0056b3;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  text-align: left;
  border: none;
  white-space: nowrap;
  text-transform: uppercase;
}

.yearly-values-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.yearly-values-table tr:hover {
  background-color: #e2e2e2;
}

.to-excel-button {
  background: linear-gradient(to right, #667eea, #764ba2);
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  margin-left: 8rem;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  font-size: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}