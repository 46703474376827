.data-container {
  width: 100%;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.data-table th {
  background-color: #0056b3; /* Darker blue */
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.75rem 1rem;
  text-align: left;
  font-size: 1.2rem;
  border: none;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.data-table td {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
}

.data-table th:first-child {
  border-radius: 8px 0 0 0;
}

.data-table th:last-child {
  border-radius: 0 8px 0 0;
}

.data-table tbody tr:hover td {
  background-color: #e3f2fd;
}

.data-table .total-row td {
  background-color: #d0e7fa; /* Slightly darker blue */
  font-weight: bold;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

  .data-table th,
  .data-table td {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .data-container {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {

  .data-table th,
  .data-table td {
    font-size: 0.7rem;
    padding: 0.4rem;
  }

  .data-container {
    padding: 0.25rem;
  }
}