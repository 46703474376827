/* src/components/Pages/LoginPage.module.css */
.loginBody {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(to right, #667eea, #764ba2);
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    animation: fadeIn 0.7s ease-in-out;
}

.loginContainer {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    width: 350px;
    max-width: 90%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginTitle {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.loginButton {
    width: 100%;
    padding: 12px;
    background-color: #667eea;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.loginButton:hover {
    background-color: #5a67d8;
}

/* Animation for the entire page */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animation for the entire page */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.loginBody {
    animation: fadeIn 0.7s ease-in-out;
}