/* Scoped styles for the ErrorPage using CSS Modules */
.container {
    align-items: center;
    animation: fadeIn 0.7s ease-in-out;
    display: flex;
    font-size: x-large;
    height: 100vh;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100%;
}

.content {
    text-align: center;
    padding: 20px;
}

.header {
    font-size: 3em;
    margin: 0;
}

.subheader {
    font-size: 1.5em;
    margin: 10px 0;
}

.message {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.details {
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: left;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.detailsHeader {
    font-size: 1.3em;
}

.detailsMessage {
    white-space: pre-wrap;
    word-break: break-word;
    background-color: #34495e;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #7f8c8d;
}

.button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #c0392b;
}