.navbar {
  background-color: #333;
  width: var(--navbar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navbar-item {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}

.navbar-item a {
  color: white;
  text-align: left;
  text-decoration: none;
  display: block;
  padding: 1rem;
  transition: background-color 0.3s ease;
}

.navbar-item .active {
  background-color: #4b6cb7; /* A vibrant background color for the active item */
  color: #fff; /* White text for contrast */
  font-weight: bold;
  border-radius: 8px; /* Smoothly rounded corners */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-item .active:hover {
  background-color: #3a539b; /* Slightly darker on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  transform: translateY(-2px); /* Lift effect */
}


/* User */
.navbar-user {
  color: white;
  margin-top: auto;
  padding: 2rem 1rem;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signout-button {
  background: linear-gradient(to right, #667eea, #764ba2);
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  font-size: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.signout-button:hover {
  background: linear-gradient(45deg, #f06595, #ff6b6b);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.signout-button:active {
  transform: translateY(0);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}