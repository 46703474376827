@import './shared.css';

.index-product-container {
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
  left: 100px;
  z-index: 5;
}

/*Emojis*/
.green-background {
  background-color: rgb(0, 255, 0, 0.75);
}

.red-background {
  background-color: rgb(255, 0, 0, 0.65);
}

.yellow-background {
  background-color: yellow;
}