.product-container {
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: center;
}

.excel-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #000;
  text-align: center;
}

.excel-table th {
  background-color: #0056b3;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.5rem 0.5rem;
  text-align: center;
  font-size: 0.7rem;
  white-space: nowrap;
  min-width: 2vw;
}

.selected-column {
  background-color: #e0e0e0 !important;
  color: black !important;
  font-weight: bold !important;
}

.excel-table td {
  border-bottom: 1px dotted black;
  box-sizing: border-box;
  height: 3vh;
  outline: none;
  text-align: center;
  font-size: medium;
}

.excel-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.excel-table tbody tr:hover {
  background-color: #e3f2fd;
}

/* Common styles for all product headers */
.h2-product {
  white-space: nowrap;
  color: white;
  padding: 0.75rem;
  position: sticky;
  margin: 0;
  top: 4;
}

/* Specific background colors for each product */
.h2-product-nwe-naphtha {
  background-color: #1f77b4;
}

.h2-product-nwe-propane {
  background-color: #ff7f0e;
}

.h2-product-mb-propane {
  background-color: #2ca02c;
}

.h2-product-fei-propane {
  background-color: black;
  color: white;
}

.h2-product-cp-propane {
  background-color: darkgoldenrod;
}

/* Fake header to align with product headers */
.h2-fake-header {
  background-color: white;
  color: transparent;
  z-index: -1;
}

.product-container h2 {
  margin: 0;
}

.swal-wide{
  width:300px !important;
  height:350px !important;
}