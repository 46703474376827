.tooltip-container {
  position: relative;
}

.tooltip-container .tooltip-text {
  background-color: black;
  border-radius: 6px;
  bottom: 125%; /* Adjust as needed */
  color: #fff;
  font-size: 1.25rem; /* Adjust font size */
  font-weight: bold; /* Bold text */
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  text-align: center;
  visibility: hidden;
  width: 300px;
  z-index: 100;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
