/* src/pages/NotAuthorized.module.css */
.notAuthorizedBody {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.7s ease-in-out;
  background-color: #f8f9fa;
}

.notAuthorizedContainer {
  text-align: center;
  padding: 40px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notAuthorizedTitle {
  font-size: 6rem;
  color: #333;
  margin-bottom: 20px;
}

.notAuthorizedMessage {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 30px;
}

/* Animation for the entire page */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}