.column-input {
  background-color: transparent;
  border: none;
  text-align: center;
  width: 100%;
  font-family: Garamond, serif;
  font-size: medium;
}

.column-input.selected {
  border: 2px solid #0056b3; /* Larger border with a darker blue */
  background-color: #003d80; /* Darker blue background */
  color: #ffffff; /* Ensure the text is readable on the dark background */
  outline: none; /* Remove default outline */
}

.column-input-container-green {
  background-color: rgb(0, 255, 0);
}

.column-input-container-red {
  background-color: red;
}

.column-input-container-standard {
  background-color: #99CCFF;
}

.column-input-container-dark {
  background-color: #66B2FF;
}

.column-input-extra-cell-green {
  background-color: rgb(0, 255, 0);
}

.column-input-extra-cell-red {
  background-color: red;
}

.column-input-extra-cell-dark {
  background-color: #FFB84D;
}