.input-field {
  display: flex;
  flex-wrap: wrap; /* Allow inputs to wrap if there are too many */
  gap: 1em; /* Space between inputs */
  padding: 1em;
  align-items: center; /* Vertically center inputs with labels */
}

.input-label {
  display: flex;
  flex-direction: column; /* Label above input by default */
  align-items: flex-start;
}

.input-field.horizontal .input-label {
  flex-direction: row; /* Make label and input appear side by side */
  align-items: center;
}

.input-field input[type="text"],
.input-field input[type="checkbox"] {
  margin-left: 0.5em; /* Space between label and input */
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.input-field input:focus {
  border-color: #007bff;
  outline: none;
}

.main-container {
  padding: 2em;
  background-color: #ffffff;
}
