:root {
  --navbar-width: 107px; /* A fixed width in pixels that can be adjusted */
  --navbar-width-mobile: 70px; /* Narrower navbar width for smaller screens */
}

body,
html {
  box-sizing: border-box;
  display: flex;
  font-family: Garamond, serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
}

.main-content {
  margin-left: var(--navbar-width);
  flex-grow: 1;
  padding: 1rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  :root {
    --navbar-width: var(--navbar-width);
  }

  .main-content {
    margin-left: var(--navbar-width);
  }
}

@media (max-width: 480px) {
  :root {
    --navbar-width: 175px; /* Even narrower for very small screens */
  }

  .main-content {
    margin-left: var(--navbar-width);
  }
}
